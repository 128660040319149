'use client';

import React, { useEffect, useState } from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

const Label = styled(Typography)(({ theme }) => ({
	textAlign: 'center',
	fontWeight: 800,
	fontSize: `clamp(${theme.typography.pxToRem(12)}, 4vw, ${theme.typography.pxToRem(20)}) !important`,
	[theme.breakpoints.up('sm')]: {
		fontSize: `${theme.typography.pxToRem(24)} !important`
	},
	[theme.breakpoints.up('md')]: {
		textAlign: 'right',
		fontSize: `clamp(${theme.typography.pxToRem(18)}, 2vw, ${theme.typography.pxToRem(24)}) !important`
	},
	[theme.breakpoints.up('lg')]: {
		textAlign: 'right',
		fontSize: `clamp(${theme.typography.pxToRem(24)}, 1.8vw, ${theme.typography.pxToRem(30)}) !important`
	}
}));

const SloganWords = ({ slogans }) => {
	const theme = useTheme();

	const [currentActiveWordIndex, setCurrentActiveWordIndex] = useState(0);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentActiveWordIndex(prevIndex => (prevIndex + 1) % slogans.length);
		}, 5000);

		return () => clearInterval(intervalId);
	}, [slogans]);

	return (
		<Grid container spacing={2} justifyContent={{ xs: 'center', md: 'flex-end' }}>
			{slogans.map((slogan, idx) => (
				<Grid item key={slogan}>
					<Label
						variant="h5"
						sx={{
							textShadow: idx === currentActiveWordIndex && `0px 0px 16px ${theme.palette.vMaroon.light}`,
							color:
								idx === currentActiveWordIndex ? theme.palette.vMaroon.main : theme.palette.text.primary
						}}>
						{slogan}
					</Label>
				</Grid>
			))}
		</Grid>
	);
};

export default SloganWords;
