'use client';

import React from 'react';
import { Box, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled, useTheme } from '@mui/material/styles';
import { enqueueSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { database } from '@volenday/sdk';
import { isEmpty } from 'lodash';
import * as yup from 'yup';
import { isEmail } from 'validator';

const StyledTextField = styled(TextField)(({ theme }) => ({
	color: theme.palette.vMaroon.main,
	width: '100%',
	height: '55px',
	'& .MuiOutlinedInput-root': {
		borderRadius: 8,
		'& fieldset': {
			borderColor: '#AEAEB2',
			borderWidth: 2
		}
	},
	[theme.breakpoints.up('md')]: {
		width: '80%',
		'& .MuiOutlinedInput-root': {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
			'& fieldset': {
				borderRight: 'none'
			}
		}
	}
}));

const inputSchema = yup.object({
	email: yup
		.string()
		.email()
		.required()
		.test('validate email', 'Email must be a valid email', value => isEmail(value))
});

const SubscribeToNewsLetterForm = ({ token, environment }) => {
	const theme = useTheme();

	const defaultValues = {
		email: ''
	};
	const {
		control,
		handleSubmit,
		reset,
		formState: { isSubmitting, errors }
	} = useForm({
		defaultValues,
		resolver: yupResolver(inputSchema)
	});

	const onSubmit = async data => {
		try {
			const { totalResults } = await database
				.get({ entity: 'MailingList', token, environment })
				.all()
				.filter({ EmailAddress: data.email })
				.exec();
			if (totalResults === 0) {
				await database
					.create({ entity: 'MailingList', token, environment })
					.data({ EmailAddress: data.email })
					.exec();
				enqueueSnackbar('Thanks for subscribing! Stay tuned for updates.', {
					variant: 'success'
				});
			} else {
				enqueueSnackbar('You already subscribed to our newsletter.', {
					variant: 'success'
				});
			}
			reset(defaultValues);
		} catch (error) {
			enqueueSnackbar(error.message, { variant: 'error' });
			console.log(error);
		}
	};

	const StyledButton = styled(LoadingButton)(({ theme }) => ({
		width: '100%',
		height: '57px',
		borderRadius: 8,
		color: theme.palette.vMaroon.main,
		marginTop: !isEmpty(errors) ? theme.spacing(3) : theme.spacing(1),
		fontWeight: 800,
		borderColor: theme.palette.vMaroon.main,
		borderWidth: 2,
		'&:hover': {
			borderColor: theme.palette.vMaroon.main,
			backgroundColor: theme.palette.vMaroon.light
		},
		[theme.breakpoints.up('md')]: {
			width: '20%',
			marginTop: '-1px',
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0
		}
	}));

	return (
		<Box
			sx={{
				width: {
					xs: `100%`,
					md: `calc(45vw - ${theme.spacing(3)})`
				},
				maxWidth: '500px',
				height: '100%',
				mx: { xs: 'auto', md: 0 }
			}}>
			<Controller
				control={control}
				name="email"
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<StyledTextField
						error={!!error}
						autoComplete="off"
						variant="outlined"
						name="email"
						placeholder="Your Email Address"
						helperText={error ? error.message : ''}
						onChange={onChange}
						value={value}
					/>
				)}
			/>
			<StyledButton loading={isSubmitting} variant="outlined" onClick={handleSubmit(onSubmit)}>
				Join
			</StyledButton>
		</Box>
	);
};

export default SubscribeToNewsLetterForm;
