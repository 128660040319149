'use client';

import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSwiperSlide } from 'swiper/react';

const StyledCountryName = styled(Typography)(({ theme, isactive }) => ({
	color: isactive && theme.palette.vMaroon.main,
	fontSize: `clamp(${theme.typography.pxToRem(24)}, 10vw, ${theme.typography.pxToRem(40)}) !important`,
	[theme.breakpoints.up('sm')]: {
		fontSize: `${theme.typography.pxToRem(60)} !important`
	},
	[theme.breakpoints.up('md')]: {
		fontSize: `${theme.typography.pxToRem(60)} !important`
	},
	[theme.breakpoints.up('lg')]: {
		fontSize: `${theme.typography.pxToRem(82)} !important`
	}
}));

const CountryName = ({ name }) => {
	const swiperSlide = useSwiperSlide();

	return (
		<StyledCountryName variant="h3" isactive={swiperSlide.isActive ? 1 : 0}>
			{name}
		</StyledCountryName>
	);
};

export default CountryName;
