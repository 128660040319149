'use client';

import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { find, isEmpty } from 'lodash';

const StyledLink = styled(Link)(({ theme }) => ({
	'&:hover': {
		color: theme.palette.vMaroon.main,
		textDecoration: 'underline'
	}
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
	backgroundColor: '#222222',
	'&:hover': {
		backgroundColor: '#555555'
	}
}));

const SocialLinksWithCopyright = ({ socialMediaLinks, companyInformation }) => {
	return (
		<Box
			sx={{
				height: '100%',
				textAlign: { xs: 'center', md: 'left' }
			}}>
			<Typography sx={{ fontWeight: 'bold' }}>
				&copy; {new Date().getFullYear()} <StyledLink href="/">Volenday</StyledLink>. All rights reserved
			</Typography>
			<Box
				sx={{
					my: 2,
					display: 'flex',
					gap: 2,
					alignItems: 'center',
					justifyContent: { xs: 'center', md: 'left' }
				}}>
				{!isEmpty(socialMediaLinks) &&
					socialMediaLinks.map(socialMediaLink => (
						<a
							key={socialMediaLink?.Id}
							href={socialMediaLink?.Url}
							target="_blank"
							title={socialMediaLink?.Name}
							rel="noopener norefferer">
							<StyledIconButton>
								<Image
									src={socialMediaLink?.Icon}
									width={16}
									height={16}
									alt={socialMediaLink?.Name}
									title={socialMediaLink?.Name}
								/>
							</StyledIconButton>
						</a>
					))}
			</Box>
			<Typography
				sx={{
					'& a': {
						fontWeight: 800,
						color: theme => theme.palette.vMaroon.main,
						'&:hover': {
							textDecoration: 'underline'
						}
					}
				}}>
				<Link href="/contact-us">
					{!isEmpty(companyInformation)
						? find(companyInformation, { Name: 'Email' }).Value || 'contact@volenday.com'
						: 'contact@volenday.com'}
				</Link>
			</Typography>
			<Typography
				sx={{
					'& a': {
						fontWeight: 800,
						color: theme => theme.palette.vMaroon.main,
						'&:hover': {
							textDecoration: 'underline'
						}
					}
				}}>
				<Link href="/privacy-policy">Privacy Policy</Link>
			</Typography>
		</Box>
	);
};

export default SocialLinksWithCopyright;
