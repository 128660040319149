'use client';

import React from 'react';
// import Link from 'next/link';
import { Box, Typography, Container, Toolbar, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';

import useIsDarkMode from '@/app/lib/hooks/useIsDarkMode';
import Clients from '@/app/ui/Banner/Clients';
import SloganWords from '@/app/ui/Banner/SloganWords';
import { transform } from 'lodash';
// import CTA from '@/app/ui/Buttons/CTA';

const SloganWordsContainer = styled(Box)(({ theme }) => ({
	position: 'absolute',
	bottom: 0,
	right: 0,
	padding: theme.spacing(0, 3),
	height: '60px',
	width: '50%',
	display: 'flex',
	justifyContent: 'flex-end',
	alignItems: 'center',
	zIndex: 3,
	[theme.breakpoints.up('md')]: {
		borderBottom: `2px solid ${theme.palette.vMaroon.main}`,
		height: '110px'
	}
}));

const HeroText = styled(Typography)(({ theme }) => ({
	maxWidth: '25ch',
	fontSize: `${theme.typography.pxToRem(34)} !important`,
	color: theme.palette.text.primary,
	'& b': {
		fontSize: `${theme.typography.pxToRem(44)} !important`
	},
	[theme.breakpoints.up('sm')]: {
		fontSize: `${theme.typography.pxToRem(42)} !important`,
		'& b': {
			fontSize: `${theme.typography.pxToRem(56)} !important`
		}
	},
	[theme.breakpoints.up('md')]: {
		fontSize: `${theme.typography.pxToRem(56)} !important`,
		'& b': {
			fontSize: `${theme.typography.pxToRem(68)} !important`
		}
	},
	[theme.breakpoints.up('lg')]: {
		fontSize: `${theme.typography.pxToRem(64)} !important`,
		'& b': {
			fontSize: `${theme.typography.pxToRem(76)} !important`
		}
	}
}));

const Content = styled(Box)(({ theme }) => ({
	marginTop: theme.spacing(1),
	maxWidth: '55ch',
	fontSize: `${theme.typography.pxToRem(14)} !important`,
	color: theme.palette.text.primary,
	[theme.breakpoints.up('sm')]: {
		fontSize: `${theme.typography.pxToRem(18)} !important`
	},
	[theme.breakpoints.up('md')]: {
		fontSize: `${theme.typography.pxToRem(20)} !important`
	}
}));

const slogans = ['Bespoke', 'Trusted', 'Passionate', 'Dedicated'];

const Banner = ({ details, clients }) => {
	const isDarkMode = useIsDarkMode();
	const upMd = useMediaQuery(theme => theme.breakpoints.up('md'));

	const BannerContainer = styled(Box)(({ theme }) => ({
		overflow: 'hidden',
		position: 'relative',
		minHeight: 'calc(100dvh - 100px)',
		height: 'fit-content',
		maxHeight: '1080px',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		paddingTop: '3rem',
		'&:before': {
			content: '""',
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			background: isDarkMode
				? `linear-gradient(90deg, ${theme.palette.vNavyBlue.main}99 0%, ${theme.palette.vNavyBlue.main}70 100% )`
				: 'linear-gradient(90deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.25) 100%)',
			zIndex: 1
		},
		[theme.breakpoints.up('md')]: { minHeight: '100dvh' }
	}));

	const CompanyName = styled(Typography)(({ theme }) => ({
		fontSize: 'clamp(16px, 15vw, 60px)',
		fontWeight: 800,
		color: 'transparent',
		WebkitTextStrokeWidth: '1.2px',
		WebkitTextStrokeColor: isDarkMode ? '#f2f2f2' : '#454545',
		opacity: 0.3,
		lineHeight: 0.75,
		padding: '2rem 0 0',
		pointerEvents: 'none',
		userSelect: 'none',
		[theme.breakpoints.up('md')]: {
			fontSize: 'clamp(32px, 10vw, 170px)',
			padding: '1rem 0 5rem'
		}
	}));

	const ClientsContainer = styled(Box)(({ theme }) => ({
		position: 'absolute',
		bottom: -1,
		overflow: 'hidden',
		width: '55%',
		zIndex: 4,
		height: '60px',
		[theme.breakpoints.up('md')]: {
			height: '110px',
			backgroundColor: theme.palette.vMaroon.main,
			clipPath: 'polygon(0 0, 85% 0, 100% 100%, 0 100%)',
			'&::after': {
				content: '""',
				position: 'absolute',
				top: 2,
				right: 2,
				trasform: 'translateY(-50%)',
				width: '100%',
				height: '100%',
				backgroundColor: isDarkMode ? theme.palette.vNavyBlue.main : theme.palette.vDirtyWhite.main,
				clipPath: 'polygon(0 0, 85% 0, 100% 100%, 0 100%)'
			}
		}
	}));

	const VideoContainer = styled(Box)(({ theme }) => ({
		'& video': {
			position: 'absolute',
			top: 0,
			left: '50%',
			transform: 'translateX(-50%)',
			minWidth: '100%',
			minHeight: '100%',
			[theme.breakpoints.up('md')]: {
				transform: 'none',
				left: 0
			}
		}
	}));

	return (
		<>
			<BannerContainer>
				<Box
					sx={{
						position: 'absolute',
						bottom: 0,
						left: '50%',
						transform: 'translate(-50%, 50%)',
						width: '40vw',
						height: '40vw',
						minWidth: '250px',
						minHeight: '250px',
						maxWidth: '900px',
						maxHeight: '900px',
						borderRadius: '50%',
						background: theme => `${theme.palette.vMaroon.main}35`,
						zIndex: 1,
						pointerEvents: 'none',
						filter: 'blur(100px)'
					}}
				/>
				<VideoContainer>
					<video autoPlay loop muted playsInline poster="/video-banner-thumbnail.webp">
						<source src="/hero-video.mp4" type="video/mp4" />
						<source src="/hero-video.webm" type="video/webm" />
					</video>
				</VideoContainer>
				<Container maxWidth={false} sx={{ zIndex: 4 }}>
					<Box sx={{ py: { xs: 3, md: 6 }, mb: { xs: '100px', md: 0 } }}>
						<Box>
							<HeroText variant="h1">
								<b>{details?.Title}</b> <br /> {details?.Subtitle}
							</HeroText>
							<Content
								dangerouslySetInnerHTML={{
									__html: details?.Content
								}}
							/>
						</Box>
						<CompanyName>VOLENDAY</CompanyName>
					</Box>
				</Container>
				<ClientsContainer>
					{upMd && (
						<Box
							className="serviceLogoContainer"
							sx={{
								position: 'absolute',
								right: '15%',
								width: 'calc(100% - 15% )'
							}}>
							<Clients clients={clients} />
						</Box>
					)}
				</ClientsContainer>
				<SloganWordsContainer>
					{upMd && (
						<Box className="slogansContainer" sx={{ position: 'absolute', left: '10%', pr: 4 }}>
							<SloganWords slogans={slogans} />
						</Box>
					)}
				</SloganWordsContainer>
			</BannerContainer>
			{!upMd && (
				<Box
					sx={{
						position: 'relative',
						backgroundColor: theme =>
							isDarkMode ? theme.palette.vNavyBlue.main : theme.palette.vDirtyWhite.main
					}}>
					<Container maxWidth="xl" sx={{ display: { xs: 'block', md: 'none' } }}>
						<Box
							sx={{
								width: '100%',
								position: 'absolute',
								bottom: 'calc(100% + 16px)',
								left: '50%',
								transform: 'translateX(-50%)',
								zIndex: 2
							}}>
							<SloganWords slogans={slogans} />
						</Box>
						<Box sx={{ mb: { xs: 1, sm: 2 } }}>
							<Clients clients={clients} />
						</Box>
					</Container>
				</Box>
			)}
		</>
	);
};

export default Banner;
