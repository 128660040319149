'use client';

import React from 'react';
import Image from 'next/image';
import { Box, useMediaQuery } from '@mui/material';
import { isEmpty } from 'lodash';
import Link from 'next/link';

import useIsDarkMode from '@/app/lib/hooks/useIsDarkMode';

import useUI from '@/app/store/useUI';

const Clients = ({ clients }) => {
	const isDarkMode = useIsDarkMode();
	const { swiper } = useUI();

	const isUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));

	return (
		<Box
			sx={{
				position: { xs: 'relative', md: 'absolute' },
				left: 0,
				width: '100%',
				height: { xs: '100px', md: '110px' },
				zIndex: 3,
				display: 'flex',
				alignItems: 'center',
				justifyContent: { xs: 'center', md: 'space-between' },
				gap: 2,
				pl: { xs: 0, md: 3 },
				'& img': {
					filter: isDarkMode ? 'brightness(0) invert(1)' : 'grayscale(100%) invert(100%)',
					'&:hover': {
						filter: isDarkMode ? 'grayscale(0%)' : 'grayscale(0%) invert(0%)'
					}
				}
			}}>
			{!isEmpty(clients) &&
				clients.map(client => {
					return (
						<Box
							key={client.Id}
							sx={{
								position: 'relative',
								width: { xs: '20%', sm: '110px', md: '90px', lg: '110px' },
								height: { xs: '30px', sm: '45px', md: '40px', lg: '45px' }
							}}>
							<Link
								href={isUpMd ? '#our-businesses' : '#our-businesses-carousel'}
								onClick={() => {
									// assuminng clients and service banners has the same order
									swiper.slideToLoop(client.Order, 1500);
								}}>
								<Image
									src={client.Logo.url}
									alt={client.Name}
									title={client.Name}
									fill
									sizes="(max-width:768px) 20vw, 15vw"
									priority
									style={{ objectFit: 'contain' }}
								/>
							</Link>
						</Box>
					);
				})}
		</Box>
	);
};

export default Clients;
