'use client';

import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const SectionContainer = ({ children }) => {
	const StyledSectionContainer = styled(Box)(({ theme }) => ({
		position: 'relative',
		padding: theme.spacing(8, 0, 16),
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(10, 0, 16)
		}
	}));

	return <StyledSectionContainer id="executive-team">{children}</StyledSectionContainer>;
};

export default SectionContainer;
