'use client';

import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';

import useIsDarkMode from '@/app/lib/hooks/useIsDarkMode';

const Title = styled(Typography)(({ theme }) => ({
	zIndex: 2,
	wordBreak: 'break-word',
	fontWeight: 800,
	marginBottom: theme.spacing(1),
	fontSize: `clamp(${theme.typography.pxToRem(32)}, 3vw, ${theme.typography.pxToRem(36)}) !important`,
	[theme.breakpoints.up('md')]: {
		fontSize: `clamp(${theme.typography.pxToRem(36)}, 2vw, ${theme.typography.pxToRem(48)}) !important`
	}
}));

const Content = styled(Box)(({ theme }) => ({
	zIndex: 2,
	fontSize: theme.typography.pxToRem(18),
	'& p': {
		marginBottom: 0
	},
	'& b': {
		color: theme.palette.vMaroon.main
	},
	[theme.breakpoints.up('md')]: {
		fontSize: theme.typography.pxToRem(20)
	}
}));

const Card = ({ details, list = [] }) => {
	const isDarkMode = useIsDarkMode();

	const Container = styled(Box)(({ theme }) => ({
		padding: theme.spacing(4),
		position: 'relative',
		height: '100%',
		minHeight: '250px',
		backgroundColor: isDarkMode ? 'rgba(11,18,21, .75)' : theme.palette.vGray.light,
		border: isDarkMode ? `1px solid ${theme.palette.vGray.main}` : `1px solid ${theme.palette.vGray.dark}`,
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		'& li': {
			paddingBottom: theme.spacing(1)
		},
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(2, 4),
			minHeight: '340px'
		}
	}));

	return (
		<Container>
			<Title variant="h3">{details?.Title}</Title>
			{isEmpty(list) && (
				<Content
					dangerouslySetInnerHTML={{
						__html: details?.Content
					}}
				/>
			)}
			{!isEmpty(list) && (
				<ul>
					{list.map(item => (
						<li key={item.Id}>
							<Content>
								<b>{item.Title}</b> - {item.Content}
							</Content>
						</li>
					))}
				</ul>
			)}
		</Container>
	);
};

export default Card;
