'use client';

import React from 'react';
import { Box, Grid, Typography, Divider, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';

import useIsDarkMode from '@/app/lib/hooks/useIsDarkMode';

const SectionHeader = ({ title, content }) => {
	const isDarkMode = useIsDarkMode();
	const upMd = useMediaQuery(theme => theme.breakpoints.up('md'));

	const Title = styled(Typography)(({ theme }) => ({
		fontWeight: 800,
		fontSize: `${theme.typography.pxToRem(42)} !important`,
		[theme.breakpoints.up('sm')]: {
			fontSize: `${theme.typography.pxToRem(50)} !important`
		},
		[theme.breakpoints.up('md')]: {
			fontSize: `${theme.typography.pxToRem(56)} !important`
		},
		[theme.breakpoints.up('lg')]: {
			fontSize: `${theme.typography.pxToRem(64)} !important`
		}
	}));

	const Content = styled(Box)(({ theme }) => ({
		fontSize: theme.typography.pxToRem(18),
		[theme.breakpoints.up('md')]: {
			fontSize: theme.typography.pxToRem(20)
		}
	}));

	const StyledDivider = styled(Divider)(({ theme }) => ({
		backgroundColor: isDarkMode ? theme.palette.divider : '#43484A'
	}));

	return (
		<Box sx={{ mt: { xs: 0, md: 4 }, mb: 2 }}>
			<Grid container rowSpacing={{ xs: 2, md: 0 }} columnSpacing={{ xs: 0, md: 2 }}>
				<Grid item xs={12} md={5}>
					<Title variant="h2">{title}</Title>
				</Grid>
				<Grid item xs={12} md={2}>
					<Box
						sx={{
							height: { xs: 'auto', md: '100%' },
							display: { xs: 'block', md: 'flex' },
							justifyContent: 'center'
						}}>
						{upMd ? <StyledDivider orientation="vertical" /> : <StyledDivider orientation="horizontal" />}
					</Box>
				</Grid>
				<Grid item xs={12} md={5}>
					<Box sx={{ height: '100%', display: 'grid', placeContent: 'center' }}>
						<Content
							dangerouslySetInnerHTML={{
								__html: content
							}}
						/>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default SectionHeader;
