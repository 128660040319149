'use client';

import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import useIsDarkMode from '@/app/lib/hooks/useIsDarkMode';

const Container = ({ children }) => {
	const isDarkMode = useIsDarkMode();

	const Container = styled(Box)(({ theme }) => ({
		padding: theme.spacing(6, 3),
		backgroundColor: isDarkMode ? theme.palette.vBlackOlive.main : theme.palette.background.default,
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(10, 0)
		}
	}));

	return <Container>{children}</Container>;
};

export default Container;
