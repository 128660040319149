'use client';

import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import useIsDarkMode from '../lib/hooks/useIsDarkMode';

const Orb = styled(Box)(({ theme }) => ({
	position: 'absolute',
	width: '50vw',
	height: '50vw',
	minWidth: '250px',
	minHeight: '250px',
	maxWidth: '900px',
	maxHeight: '900px',
	borderRadius: '50%',
	background: `${theme.palette.vMaroon.main}30`,
	zIndex: 1,
	pointerEvents: 'none',
	[theme.breakpoints.up('md')]: {
		width: '30vw',
		height: '30vw'
	}
}));

const HomepageContainer = ({ children }) => {
	const isDarkMode = useIsDarkMode();

	return (
		<Box
			sx={{
				overflow: 'hidden',
				position: 'relative',
				backgroundColor: theme => (isDarkMode ? theme.palette.vNavyBlue.main : theme.palette.vDirtyWhite.main)
			}}>
			{isDarkMode && (
				<>
					<Orb sx={{ top: '25%', left: 0, transform: 'translateX(-50%)' }} />
					<Orb sx={{ top: '65%', right: 0, transform: 'translateX(45%)' }} />
					<Orb sx={{ bottom: 0, left: 0, transform: 'translateX(-60%)' }} />
				</>
			)}
			<Box sx={{ position: 'relative', backdropFilter: 'blur(250px)', zIndex: 2 }}>{children}</Box>
		</Box>
	);
};

export default HomepageContainer;
