'use client';

import 'swiper/css';

import { Container, Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import CountryName from '@/app/ui/Geography/CountryName';

const Title = styled(Typography)(({ theme }) => ({
	fontWeight: 800,
	fontSize: `${theme.typography.pxToRem(42)} !important`,
	paddingBottom: theme.spacing(4),
	[theme.breakpoints.up('sm')]: {
		fontSize: `${theme.typography.pxToRem(50)} !important`
	},
	[theme.breakpoints.up('md')]: {
		fontSize: `${theme.typography.pxToRem(56)} !important`
	},
	[theme.breakpoints.up('lg')]: {
		fontSize: `${theme.typography.pxToRem(64)} !important`
	}
}));

const Content = styled(Box)(({ theme }) => ({
	fontSize: theme.typography.pxToRem(18),
	[theme.breakpoints.up('md')]: {
		maxWidth: '58ch',
		fontSize: theme.typography.pxToRem(20)
	}
}));

const StyledSwiper = styled(Swiper)(({ theme }) => ({
	height: '200px',
	width: 'fit-content',
	[theme.breakpoints.up('sm')]: {
		height: '250px'
	},
	[theme.breakpoints.up('md')]: {
		height: '280px',
		margin: '0 0 0 auto !important'
	},
	[theme.breakpoints.up('lg')]: {
		height: '350px',
		margin: '0 0 0 auto !important'
	}
}));

export default function GeographySection({ details, countries }) {
	return (
		<Container id="geography" maxWidth={false} sx={{ pt: { xs: 8, md: 10 } }}>
			<Grid container rowSpacing={{ xs: 3, md: 0 }} columnSpacing={{ xs: 0, md: 3 }} alignItems="center">
				<Grid item xs={12} md={5}>
					<Title variant="h2">{details?.Title}</Title>
					<Content
						dangerouslySetInnerHTML={{
							__html: details?.Content
						}}
					/>
				</Grid>
				<Grid item xs={12} md={7}>
					<StyledSwiper
						slidesPerView={3.5}
						autoplay={{
							delay: 3000,
							disableOnInteraction: false
						}}
						loop={true}
						modules={[Autoplay]}
						direction="vertical"
						className="mySwiper">
						{countries.length &&
							countries.map((country, index) => (
								<SwiperSlide key={country?.Id} style={{ height: 'fit-content' }}>
									<CountryName name={country?.Name} />
								</SwiperSlide>
							))}
					</StyledSwiper>
				</Grid>
			</Grid>
		</Container>
	);
}
