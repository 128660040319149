'use client';

import { useState, useRef } from 'react';
import Image from 'next/image';
import { Box, Typography, Grid, useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { styled, useTheme } from '@mui/material/styles';
import { isEmpty, max } from 'lodash';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import useUI from '@/app/store/useUI';
import Modal from '@/app/ui/OurWork/Carousel/Modal';

const StyledSwiper = styled(Swiper)(({ theme, shouldhidearrows }) => ({
	maxWidth: '1920px',
	margin: '0 auto',
	'.swiper-slide': {
		border: `1px solid ${theme.palette.vGray.main}`,
		height: 'auto',
		overflow: 'hidden'
	},
	'.swiper-pagination': {
		display: shouldhidearrows ? 'none' : 'block'
	},
	'.swiper-pagination-bullet': {
		border: '1px solid white',
		[theme.breakpoints.up('md')]: {
			width: '14px',
			height: '14px'
		}
	},
	'.swiper-pagination-bullet-active': {
		border: 'none',
		backgroundColor: theme.palette.vMaroon.main
	},
	'.swiper-button-prev, .swiper-button-next': {
		display: 'none',
		height: '85px !important',
		width: '85px !important',
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		transform: 'translateY(-50%)',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			margin: theme.spacing(0, 14)
		},
		[theme.breakpoints.up('lg')]: {
			margin: theme.spacing(0, 20)
		}
	},
	'.swiper-button-prev': {
		[theme.breakpoints.up('md')]: {
			display: shouldhidearrows ? 'none' : 'flex'
		},
		backgroundImage: 'url("/prev.svg")',
		'&:hover': {
			backgroundImage: 'url("/prev-contained.svg")'
		}
	},

	'.swiper-button-next': {
		[theme.breakpoints.up('md')]: {
			display: shouldhidearrows ? 'none' : 'flex'
		},
		backgroundImage: 'url("/next.svg")',
		'&:hover': {
			backgroundImage: 'url("/next-contained.svg")'
		}
	},
	'.swiper-button-next::after,.swiper-button-prev::after': {
		content: '""'
	}
}));

const Carousel = ({ banners }) => {
	const swiperRef = useRef();
	const theme = useTheme();

	const [isModalShowing, setIsModalShowing] = useState(false);

	const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
	const isContainerMaxWidth = useMediaQuery('(max-width: 1536px)');

	const { setSwiper } = useUI();

	const showModal = () => {
		setIsModalShowing(true);
		swiperRef.current.swiper?.autoplay?.pause();
	};

	const hideModal = () => {
		setIsModalShowing(false);
		swiperRef.current.swiper?.autoplay?.resume();
	};

	const handleMouseEnter = () => {
		swiperRef?.current?.swiper?.autoplay?.stop();
	};

	const handleMouseLeave = () => {
		swiperRef?.current?.swiper?.autoplay?.start();
	};

	return (
		<Box
			id="our-businesses-carousel"
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			sx={{
				px: { xs: 0, md: 3, lg: isContainerMaxWidth ? 3 : 12 },
				pt: { xs: 8, md: 4 },
				mt: { xs: -4, md: 0 }
			}}>
			<StyledSwiper
				onSwiper={setSwiper}
				ref={swiperRef}
				shouldhidearrows={isModalShowing || !isUpMd ? 1 : 0}
				navigation={true}
				pagination={{ clickable: true }}
				autoplay={{
					delay: 5000
				}}
				onSlideChangeTransitionStart={hideModal}
				centeredSlides
				modules={[Autoplay, Navigation, Pagination]}
				slidesPerView={1.14}
				spaceBetween={isUpMd ? 30 : 8}
				loop={true}
				className="mySwiper">
				{!isEmpty(banners) &&
					banners.map(banner => (
						<SwiperSlide key={banner.Id}>
							<picture>
								<source
									media={`(max-width: ${theme.breakpoints.values.md - 1}px)`}
									srcSet={banner?.SmallImage?.url}
								/>
								<Image
									src={banner?.LargeImage?.url}
									alt={banner.Name}
									title={banner.Name}
									width={100}
									height={100}
									sizes="(max-width:768px) 90vw, 95vw"
									priority
									style={{
										width: '100%',
										height: '100%',
										maxHeight: '800px',
										objectFit: 'cover',
										objectPosition: isUpMd ? 'left bottom' : 'left top'
									}}
								/>
							</picture>
							<Modal
								data={banner}
								isModalShowing={isModalShowing}
								showModal={showModal}
								hideModal={hideModal}
							/>
						</SwiperSlide>
					))}
			</StyledSwiper>
			<Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', justifyContent: 'center', mt: 2 }}>
				<Image src="/gesture.svg" alt="Swipe" width={40} height={40} />
			</Box>
		</Box>
	);
};

export default Carousel;
