'use client';

import React from 'react';
import Image from 'next/image';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import useIsDarkMode from '@/app/lib/hooks/useIsDarkMode';

const Name = styled(Typography)(({ theme }) => ({
	fontSize: `clamp(${theme.typography.pxToRem(16)}, 3.5vw, ${theme.typography.pxToRem(18)})`,
	fontWeight: 800,
	[theme.breakpoints.up('sm')]: {
		fontSize: `clamp(${theme.typography.pxToRem(18)}, 3vw, ${theme.typography.pxToRem(24)})`
	},
	[theme.breakpoints.up('md')]: {
		fontSize: `clamp(${theme.typography.pxToRem(18)}, 2vw, ${theme.typography.pxToRem(30)})`
	},
	[theme.breakpoints.up('lg')]: {
		fontSize: `clamp(${theme.typography.pxToRem(20)}, 1.5vw, ${theme.typography.pxToRem(32)})`
	}
}));

const Department = styled(Typography)(({ theme }) => ({
	fontSize: `clamp(${theme.typography.pxToRem(14)}, 3.5vw, ${theme.typography.pxToRem(16)})`,
	fontWeight: 400
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
	backgroundColor: theme.palette.vGray.main
}));

const Card = ({ name, position, department, image, linkedIn }) => {
	const isDarkMode = useIsDarkMode();

	const Position = styled(Typography)(({ theme }) => ({
		marginBottom: theme.spacing(0.5),
		fontSize: `clamp(${theme.typography.pxToRem(14)}, 3.5vw, ${theme.typography.pxToRem(16)})`,
		fontWeight: 800,
		color: isDarkMode ? theme.palette.vMaroon.main : '#6e6e6e'
	}));

	return (
		<Box
			sx={{
				position: 'relative',
				overflow: 'hidden',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				border: theme =>
					isDarkMode ? `1px solid ${theme.palette.vGray.main}` : `1px solid ${theme.palette.vGray.dark}`
			}}>
			<Box
				sx={{
					m: { xs: 2, sm: 3 },
					width: '90%',
					height: '90%',
					minHeight: { xs: '235px', sm: '255px', md: '200px', lg: '180px', xl: '210px' },
					display: 'flex',
					flexDirection: 'column',
					gap: 2,
					justifyContent: { xs: 'flex-start', md: 'space-between' }
				}}>
				<Box>
					{name && <Name>{name}</Name>}
					{position && <Position>{position}</Position>}
					{department && <Department>{department}</Department>}
				</Box>
				{linkedIn && (
					<Box>
						<a href={linkedIn} target="_blank" rel="noopener norefferer" title={`${name} LinkedIn`}>
							<StyledIconButton>
								<Image
									src="/linkedin.svg"
									width={16}
									height={16}
									alt={`${name} LinkedIn`}
									title={`${name} LinkedIn`}
								/>
							</StyledIconButton>
						</a>
					</Box>
				)}
			</Box>
			<Box
				sx={{
					position: 'absolute',
					bottom: 0,
					right: { xs: -45, sm: -50, md: -35, lg: -30 },
					pointerEvents: 'none'
				}}>
				<Box
					sx={{
						position: 'relative',
						width: { xs: '185px', sm: '255px', md: '200px', lg: '180px', xl: '220px' },
						height: { xs: '185px', sm: '255px', md: '200px', lg: '180px', xl: '220px' }
					}}>
					<Image
						src={image}
						alt={name}
						title={name}
						fill
						sizes="(max-width: 768px) 40vw, 25vw"
						style={{ filter: 'grayscale(1)', objectFit: 'contain' }}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default Card;
