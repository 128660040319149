'use client';

import React from 'react';
import { Box, Grid, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { find } from 'lodash';

import Card from '@/app/ui/CompanyValues/Card';

const Title = styled(Typography)(({ theme }) => ({
	fontWeight: 800,
	fontSize: `${theme.typography.pxToRem(42)} !important`,
	marginBottom: theme.spacing(2),
	color: theme.palette.text.primary,
	[theme.breakpoints.up('sm')]: {
		fontSize: `${theme.typography.pxToRem(50)} !important`
	},
	[theme.breakpoints.up('md')]: {
		fontSize: `${theme.typography.pxToRem(56)} !important`
	},
	[theme.breakpoints.up('lg')]: {
		fontSize: `${theme.typography.pxToRem(64)} !important`
	}
}));

const StyledBackgroundImage = styled(Box)(({ theme }) => ({
	position: 'absolute',
	top: '50%',
	left: '-80%',
	transform: 'translateY(-50%)',
	zIndex: -1,
	height: '100vh',
	// backgroundColor: theme.palette.vNavyBlue.main,
	'& img': {
		height: '100vh',
		mixBlendMode: 'difference',
		[theme.breakpoints.up('md')]: {
			height: '130vh',
			maxHeight: '1400px'
		}
	},
	[theme.breakpoints.up('md')]: {
		top: '-15%',
		left: '-35%',
		transform: 'none'
	},
	[theme.breakpoints.up('lg')]: {
		left: '-15%'
	}
}));

const CompanyValues = ({ title, details, coreValuesList }) => {
	const coreValues = find(details, d => d.Title === 'Core Values');
	const mission = find(details, d => d.Title === 'Mission');
	const vision = find(details, d => d.Title === 'Vision');

	return (
		<Box sx={{ position: 'relative', overflow: 'visible', backgroundColor: theme => theme.palette.vNavyBlue }}>
			<StyledBackgroundImage>
				<img
					src="/company-values-bg.webp"
					layout="fill"
					alt="Company Values Background"
					title="Company Values Background"
				/>
			</StyledBackgroundImage>
			<Container maxWidth={false}>
				<Box id="company-values" sx={{ pt: { xs: 8, md: 10 } }}>
					<Title>{title}</Title>
					<Grid container columnSpacing={{ xs: 0, sm: 2, xl: 4 }} rowSpacing={{ xs: 2, md: 0 }}>
						<Grid item xs={12} md={6}>
							<Card details={coreValues} list={coreValuesList} />
						</Grid>
						<Grid item xs={12} md={6}>
							<Grid container rowSpacing={{ xs: 2, xl: 4 }}>
								<Grid item xs={12}>
									<Card details={mission} />
								</Grid>
								<Grid item xs={12}>
									<Card details={vision} />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</Box>
	);
};

export default CompanyValues;
